import React from 'react';
import styled from 'styled-components';
import InteractivePILayout from '../layouts/InteractivePILayout';
import NoLayout from '../layouts/NoLayout';
import { CURRENT_SELECTED_PI, PI_PDF_URL, SHOW_INTERACTIVE_PI } from '../pi/constants';
import AdobePdfViewer from '../components/AdobePdfViewer';
import { Heading, Text } from '../util/typography';
import Container from '../pi/components/Container';
import PiPdfFooter from '../pi/components/PiPdfFooter';
import * as colors from '../util/colors';
import { Main } from '../pi/InteractivePI';
import { createLocalScaleFunction, getEnvironment } from '../util/functions';

// SEO INFORMATION
const pageMeta = {
    title: `Prescribing Information`,
    keywords: ``,
    description: ``,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/prescribing-information/","@type":"MedicalWebPage","name":"Prescribing Information (PI) for KEYTRUDA® (pembrolizumab) Injection 100mg","description":" See full prescribing information for KEYTRUDA."}`
    ]
}

const PiContainer = styled(Container)`
    margin-top: 66px;
`

const Eyebrow = styled(Text.Subtitle)`
    cursor: pointer;
    a {
        text-decoration: none;

        &:visited {
            color: ${colors.blueberry}
        }
    }
`;

// remove when PDF version is replaced
const PdfPiHeading = styled(Heading.H1)`
    margin-bottom: ${createLocalScaleFunction(10, 320, 18, 952)};
`;

const Page = ({ location }) => {   
    if (SHOW_INTERACTIVE_PI) return (
        <InteractivePILayout pageMeta={pageMeta} location={location} piKey={CURRENT_SELECTED_PI} />
    );

    // Adds a cache-busting query parameter in order for merck.com's backend to send the correct Access-Control-Allow-Origin header for the PDF
    const environmentIDs = { localhost: '1', mizer: '2', stage: '3', production: '0' };
    const embeddedPDFUrl = PI_PDF_URL + '?t=' + Date.now() + environmentIDs[getEnvironment()];

    return (            
        <NoLayout pageMeta={pageMeta} location={location}>
            <Main>
                <PiContainer>
                    <Eyebrow><a href="https://www.keytrudahcp.com" target="_blank">KEYTRUDA® (pembrolizumab) Injection 100mg</a></Eyebrow>
                    <PdfPiHeading as='h1'>Prescribing Information (PI)</PdfPiHeading>
                    <Text.Body>View or download the Prescribing Information below, an interactive version will be available soon.</Text.Body>
                    <AdobePdfViewer id={'pdf-pi-div'} fileUrl={embeddedPDFUrl} height={'450px'} />
                </PiContainer>
            </Main>
            <PiPdfFooter/>
        </NoLayout>
    ) 

}

export default Page
